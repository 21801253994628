import { useState } from 'react';
import { Imprint } from './imprint';
import { DataPrivacy } from './data-privacy';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();
  const [openImpressum, setOpenImpressum] = useState(false);
  const [openDataPrivacy, setOpenDataPrivacy] = useState(false);

  return (
    <>
      <footer className="bg-[#00BFFF] text-center px-5 pb-2 text-white">
        <div className="flex justify-center gap-4">
          <p
            className="link link-hover cursor-pointer"
            onClick={() => setOpenImpressum(true)}
          >
            {t('contact.imprint')}
          </p>
          <p
            className="link link-hover cursor-pointer"
            onClick={() => setOpenDataPrivacy(true)}
          >
            {t('contact.policy')}
          </p>
        </div>

        <p>&copy; {t('contact.rights')} </p>
      </footer>
      <Imprint open={openImpressum} setOpen={setOpenImpressum} />
      <DataPrivacy open={openDataPrivacy} setOpen={setOpenDataPrivacy} />
    </>
  );
};

import React from 'react';
import { DragCloseDrawer } from './drag-clos-drawer';
import { useTranslation } from 'react-i18next';

type PrivacyContent = {
  value: string;
};

type PrivacySection = {
  title: string;
  content: PrivacyContent[];
};

export const DataPrivacy = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();

  const privacy = t('privacy', { returnObjects: true }) as PrivacySection[];

  return (
    <DragCloseDrawer open={open} setOpen={setOpen}>
      <div className="mx-auto pb-8 max-w-2xl space-y-8 text-neutral-400">
        <h2 className="text-4xl font-bold text-neutral-200">
          {t('dataPrivacyHeader')}
        </h2>
        {privacy.map((section, index) => (
          <div key={index}>
            <h3 className="text-2xl font-semibold text-neutral-300">
              {section.title}
            </h3>
            {section.content.map((item, idx) => (
              <div key={idx} className="text-lg">
                <p
                  dangerouslySetInnerHTML={{ __html: item.value }}
                  className="mt-1"
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </DragCloseDrawer>
  );
};

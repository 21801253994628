import { useTranslation } from 'react-i18next';
import { NeumorphismButton } from './neumorphism-button';
import { useState } from 'react';
import { ContactForm } from './contact-form';

export const Contact = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <section
        id="contact"
        className="flex justify-center bg-[#00BFFF] text-white"
      >
        <div className="max-w-[1200px] w-full py-16 px-5">
          <div className="flex justify-start">
            <div className="flex flex-col gap-8">
              <div className="flex items-center">
                <div className="avatar">
                  <div className="w-24 sm:w-32 bg-[#00BFFF] rounded-full !flex items-center justify-center border-white border-4">
                    <img
                      className="!w-16 !h-16 !object-contain"
                      src="/antenne-white.png"
                      alt="antenne-white"
                    />
                  </div>
                </div>
                <div>
                  <h2 className="text-2xl md:text-5xl font-bold ml-6">
                    {t('contact.header')}
                  </h2>
                </div>
              </div>
              <div>
                <p>{t('contact.underHeaded')}</p>
              </div>
              <div className="place-self-center">
                <NeumorphismButton setOpen={setOpen} />
              </div>

              <div className="flex justify-center">
                <div className="text-[#687888] font-bold text-center max-w-[1000px] px-5">
                  <p className="mt-6">
                    Service, advice and consultancy for European railway
                    companies
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactForm open={open} setOpen={setOpen} />
    </>
  );
};

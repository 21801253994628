import React from 'react';
import { DragCloseDrawer } from './drag-clos-drawer';
import { useTranslation } from 'react-i18next';

type ImprintContent = {
  subtitle: string;
  value: string;
};

type ImprintSection = {
  title: string;
  content: ImprintContent[];
};

export const Imprint = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();

  const imprint = t('imprint', { returnObjects: true }) as ImprintSection[];

  return (
    <DragCloseDrawer open={open} setOpen={setOpen}>
      <div className="mx-auto pb-8 max-w-2xl space-y-8 text-neutral-400">
        <h2 className="text-4xl font-bold text-neutral-200">
          {t('imprintHeader')}
        </h2>
        {imprint.map((section, index) => (
          <div key={index}>
            <h3 className="text-2xl font-semibold text-neutral-300">
              {section.title}
            </h3>
            {section.content.map((item, idx) => (
              <div key={idx} className="text-lg">
                {item.subtitle && (
                  <p
                    dangerouslySetInnerHTML={{ __html: item.subtitle }}
                    className="font-medium"
                  />
                )}
                <p
                  dangerouslySetInnerHTML={{ __html: item.value }}
                  className="mt-1"
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </DragCloseDrawer>
  );
};

import React, { useState } from 'react';
import { DragCloseDrawer } from './drag-clos-drawer';
import { useTranslation } from 'react-i18next';
import { LoadAndErrorButton } from './load-button';

export type Variant = 'neutral' | 'loading' | 'error' | 'success';

export const ContactForm = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();

  const [variant, setVariant] = useState<Variant>('neutral');

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  // Handler für Eingaben
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  // Daten senden
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Verhindert das Neuladen der Seite

    try {
      setVariant('loading');
      // nodemailer!!!!!

      // Sende Formulardaten an das Backend
      const response = await fetch('http://localhost:5000/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Fehler beim Senden der E-Mail');
      }

      setVariant('success');
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      setVariant('error');
    } finally {
      setTimeout(() => {
        setVariant('neutral');
      }, 3000);
    }
  };

  return (
    <DragCloseDrawer open={open} setOpen={setOpen}>
      <div className="mx-auto md:pb-8 max-w-4xl md:h-full space-y-8 text-neutral-400 ">
        <h2 className="text-4xl font-bold text-neutral-200">
          {t('contact.contactForm')}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-12 h-full md:gap-8">
          <div className="col-span-1 md:col-span-8 md:h-full flex flex-col justify-evenly gap-16">
            <button
              className="md:!h-20 text-2xl rounded-md bg-gradient-to-br from-[#00BFFF] to-blue-600 px-4 py-2 text-zinc-50 ring-2 ring-[#00BFFF]/50 ring-offset-2 ring-offset-white transition-all hover:scale-[1.02] hover:ring-transparent active:scale-[0.98] active:ring-[#00BFFF]/70"
              onClick={() =>
                (window.location.href = 'mailto:info@trainconsult.nl')
              }
            >
              {t('contact.contactEmail')}
            </button>

            <button
              className="md:!h-20 text-2xl rounded-md bg-gradient-to-br from-[#00BFFF] to-blue-600 px-4 py-2 text-zinc-50 ring-2 ring-[#00BFFF]/50 ring-offset-2 ring-offset-white transition-all hover:scale-[1.02] hover:ring-transparent active:scale-[0.98] active:ring-[#00BFFF]/70"
              onClick={() => (window.location.href = 'tel:+31773690151')}
            >
              {t('contact.contactPhone')}
            </button>
          </div>

          {/* <div className="col-span-8 flex flex-col gap-8">
            <p>{t('contact.contactText')}</p>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="mb-2 text-sm font-medium text-neutral-300"
                >
                  {t('contact.contactName')}
                </label>
                <input
                  id="name"
                  type="text"
                  placeholder={t('contact.contactName')}
                  value={formData.name}
                  onChange={handleInputChange}
                  className="input input-bordered w-full"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="email"
                  className="mb-2 text-sm font-medium text-neutral-300"
                >
                  {t('contact.contactEmail')}
                </label>
                <input
                  id="email"
                  type="email"
                  placeholder={t('contact.contactEmail')}
                  value={formData.email}
                  onChange={handleInputChange}
                  className="input input-bordered w-full"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="subject"
                  className="mb-2 text-sm font-medium text-neutral-300"
                >
                  {t('contact.contactSubject')}
                </label>
                <input
                  id="subject"
                  type="text"
                  placeholder={t('contact.contactSubject')}
                  value={formData.subject}
                  onChange={handleInputChange}
                  className="input input-bordered w-full"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="message"
                  className="mb-2 text-sm font-medium text-neutral-300"
                >
                  {t('contact.contactMessage')}
                </label>
                <textarea
                  id="message"
                  placeholder={t('contact.contactMessage')}
                  value={formData.message}
                  onChange={handleInputChange}
                  className="textarea textarea-bordered w-full h-32"
                ></textarea>
              </div>

              <LoadAndErrorButton variant={variant} />
            </form>
          </div> */}

          <div className="col-span-1 divider before:bg-[#00BFFF] after:bg-[#00BFFF] md:divider-horizontal" />
          <div className="col-span-1 md:col-span-3 text-base">
            <p>
              <strong>{t('contact.ContactInformation')}</strong>
            </p>
            <br />
            <p>Trainconsult b.v</p>
            <p>Kaldenkerkerkerweg 20</p>
            <p>5913 AE Venlo</p>
            <p>{t('contact.adress')}</p>
            <br />
            <p>info@trainconsult.nl</p>
            <p>+31 (0) 773690151</p>
            <p>www.trainconsult.nl</p>
          </div>
        </div>
      </div>
    </DragCloseDrawer>
  );
};
